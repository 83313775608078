/* You can add global styles to this file, and also import other style files */

// ng-select2
@import "~@ng-select/ng-select/themes/default.theme.css";
// flatpicker
@import '~flatpickr/dist/flatpickr.css';

.mr-2 {
  margin-right: 5px !important;
}

// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: var(--bs-gray);
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}


// File Upload
.dropzone_sec {
  border: 2px dashed #e2e5e8 !important;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: transparent !important;
  color: var(--bs-gray-400) !important;
  margin: 0.3em 0 !important;
}

.dropzone {
  border: 2px dashed var(--bs-border-color) !important;
  padding: 0px !important;
  height: 150px !important;
}

.dropzone .dz-preview {
  width: 90% !important;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  width: 100% !important;
  height: 115px !important;
}

.dz-progress {
  display: none !important;
}

.dz-error {
  display: none !important;
}

dropzone>.dropzone.dz-wrapper {
  border: none !important;
}

// Range Slider
.ng5-slider .ng5-slider-pointer {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: #5b73e8 !important;
  z-index: 3;
  border-radius: 16px;
}

.ng5-slider .ng5-slider-pointer:after {
  display: none !important;
}

.ng5-slider .ng5-slider-selection {
  background: #5b73e8 !important;
}

body[data-bs-theme=dark] {
  .dropzone {
    background: transparent;
  }

  .ng5-slider .ng5-slider-bar {
    background: var(--bs-gray-300);
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }

  // Select 2
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #253a55 !important;
    color: #8495ab !important;
  }

  .ng-select .ng-select-container {
    background-color: var(--bs-input-bg) !important;
    border: 1px solid var(--bs-input-border-color);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--bs-gray-100);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: var(--bs-input-bg);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel {
    border: 1px solid var(--bs-gray-100);
  }

  // Sweet alert
  .swal2-popup {
    background: var(--bs-input-bg);
    color: var(--bs-body-color);
  }
}

// scroll bar
.simplebar-scrollbar:before {
  width: 100%;
  background: #a2adb7 !important;
}


// timeline

.hori-timeline {
  .owl-nav {

    .owl-prev,
    .owl-next {
      background-color: rgba(91, 115, 232, .25) !important;
      color: #5b73e8 !important;
      border-radius: 50%;
    }
  }
}

//custom CSS

// .p-datatable .p-datatable-tbody>tr>td {
//     padding: 0.5em 1rem !important;
// }

// .p-datatable .p-datatable-thead>tr>th {
//     font-size: 14px !important;
//     padding: 0.5rem 1rem !important;
//     border: 1px solid #8080808a;
// }

// .p-datatable .p-datatable-tbody>tr {
//     background: none !important;
// }

// .p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody>tr>td {
//     border-width: 1px 1px 1px 1px !important;
//     font-size: 12px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
// }

/* Compact PrimeNG Table Styles */

/* Compact and Aesthetic PrimeNG Table Styles */

// .p-datatable {
//     font-size: 0.875rem; /* Smaller font size */
//     border: 1px solid #ddd; /* Border for the table */
//     border-radius: 4px; /* Rounded corners for the table */
//     overflow: hidden; /* Ensure rounded corners are visible */
//     background-color: #f9f9f9; /* Light background color */
//   }

//   .p-datatable-header, .p-datatable-footer {
//     padding: 0.5rem; /* Reduce padding for header and footer */
//     background-color: #f1f1f1; /* Light background for header and footer */
//     border-bottom: 1px solid #ddd; /* Border bottom for header */
//     border-top: 1px solid #ddd; /* Border top for footer */
//   }

//   .p-datatable-thead > tr > th {
//     padding: 0.5rem; /* Reduce padding for table head cells */
//     background-color: #f7f7f7; /* Light background for table head */
//     border-bottom: 1px solid #ddd; /* Border bottom for table head cells */
//     border-right: 1px solid #ddd; /* Border right for table head cells */
//     text-align: left; /* Align text to the left */
//     color: #333; /* Text color */
//     font-weight: bold; /* Bold text */
//   }

//   .p-datatable-tfoot > tr > td {
//     padding: 0.5rem; /* Reduce padding for table foot cells */
//     background-color: #f7f7f7; /* Light background for table foot */
//     border-top: 1px solid #ddd; /* Border top for table foot cells */
//     border-right: 1px solid #ddd; /* Border right for table foot cells */
//     text-align: left; /* Align text to the left */
//     color: #333; /* Text color */
//   }

//   .p-datatable-tbody > tr > td {
//     padding: 0.5rem; /* Reduce padding for table body cells */
//     border-bottom: 1px solid #ddd; /* Border bottom for table body cells */
//     border-right: 1px solid #ddd; /* Border right for table body cells */
//     background-color: #fff; /* White background for table body */
//   }

//   .p-datatable-tbody > tr:nth-child(even) > td {
//     background-color: #f5f5f5; /* Alternate row color */
//   }

//   .p-datatable-tbody > tr:hover > td {
//     background-color: #eaeaea; /* Hover color */
//   }

//   .p-datatable-tbody > tr > td > .p-column-title {
//     font-size: 0.75rem; /* Smaller font size for column titles */
//     padding: 0.25rem; /* Reduce padding for column titles */
//     color: #666; /* Text color for column titles */
//   }

//   .p-paginator {
//     padding: 0.5rem; /* Reduce padding for paginator */
//     font-size: 0.875rem; /* Smaller font size for paginator */
//     border-top: 1px solid #ddd; /* Border top for paginator */
//     background-color: #f1f1f1; /* Light background for paginator */
//   }

//   /* Adjust input, button, and icon sizes within the table */
//   .p-datatable input, .p-datatable button, .p-datatable .p-paginator .p-paginator-element {
//     font-size: 0.875rem; /* Smaller font size */
//     padding: 0.25rem 0.5rem; /* Reduce padding */
//   }

//   /* Sort Icon Styles */
//  .p-sortIcon {
//     font-size: 0.75rem; /* Smaller font size for sort icon */
//     margin-left: 0.25rem; /* Margin to separate icon from text */
//     color: #999; /* Lighter color for sort icon */
//   }

//   .p-sortable-column .p-sortable-column-icon {
//     position: absolute;
//     right: 0.5rem;
//     top: 50%;
//     transform: translateY(-50%);
//   }

//   /* Responsive adjustments */
//   @media screen and (max-width: 768px) {
//     .p-datatable {
//       font-size: 0.75rem; /* Even smaller font size for mobile */
//     }

//     .p-datatable-header, .p-datatable-footer, .p-datatable-thead > tr > th, .p-datatable-tfoot > tr > td, .p-datatable-tbody > tr > td {
//       padding: 0.25rem; /* Further reduce padding for mobile */
//     }

//     .p-paginator {
//       padding: 0.25rem; /* Further reduce padding for paginator on mobile */
//       font-size: 0.75rem; /* Smaller font size for paginator on mobile */
//     }
//   }


/* Compact and Aesthetic PrimeNG Table Styles */

.p-datatable {
  font-size: 0.875rem;
  /* Smaller font size */
  border: 1px solid #ddd;
  /* Border for the table */
  border-radius: 4px;
  /* Rounded corners for the table */
  overflow: hidden;
  /* Ensure rounded corners are visible */
  background-color: #f9f9f9;
  /* Light background color */
  font-size: 12px
}

.p-datatable-header,
.p-datatable-footer {
  padding: 0.5rem;
  /* Reduce padding for header and footer */
  background-color: #f1f1f1;
  /* Light background for header and footer */
  border-bottom: 1px solid #ddd;
  /* Border bottom for header */
  border-top: 1px solid #ddd;
  /* Border top for footer */
}

.p-datatable-thead>tr>th {
  padding: 0.5rem;
  /* Reduce padding for table head cells */
  background-color: #f7f7f7;
  /* Light background for table head */
  border-bottom: 1px solid #ddd;
  /* Border bottom for table head cells */
  border-right: 1px solid #ddd;
  /* Border right for table head cells */
  text-align: left;
  /* Align text to the left */
  color: #333;
  /* Text color */
  font-weight: bold;
  /* Bold text */
  position: relative;
  /* For positioning sort icons */
}

.p-datatable-tfoot>tr>td {
  padding: 0.5rem;
  /* Reduce padding for table foot cells */
  background-color: #f7f7f7;
  /* Light background for table foot */
  border-top: 1px solid #ddd;
  /* Border top for table foot cells */
  border-right: 1px solid #ddd;
  /* Border right for table foot cells */
  text-align: left;
  /* Align text to the left */
  color: #333;
  /* Text color */
}

.p-datatable-tbody {
  overflow-y: auto;
  /* Enable vertical scrolling */
  flex-grow: 1;
  /* Allow the body to grow and take up remaining space */
}

.p-datatable-tbody>tr>td {
  padding: 0.3rem;
  /* Reduce padding for table body cells */
  border-bottom: 1px solid #ddd;
  /* Border bottom for table body cells */
  border-right: 1px solid #ddd;
  /* Border right for table body cells */
  background-color: #fff;
  /* White background for table body */
}

.p-datatable-tbody>tr:nth-child(even)>td {
  background-color: #f5f5f5;
  /* Alternate row color */
}

.p-datatable-tbody>tr:hover>td {
  background-color: #eaeaea;
  /* Hover color */
}

.p-datatable-tbody>tr>td>.p-column-title {
  font-size: 0.75rem;
  /* Smaller font size for column titles */
  padding: 0.25rem;
  /* Reduce padding for column titles */
  color: #666;
  /* Text color for column titles */
}

.p-paginator {
  padding: 0.5rem;
  /* Reduce padding for paginator */
  font-size: 0.875rem;
  /* Smaller font size for paginator */
  border-top: 1px solid #ddd;
  /* Border top for paginator */
  background-color: #f1f1f1;
  /* Light background for paginator */
}

/* Adjust input, button, and icon sizes within the table */
.p-datatable input,
.p-datatable button,
.p-datatable .p-paginator .p-paginator-element {
  font-size: 0.875rem;
  /* Smaller font size */
  padding: 0.25rem 0.5rem;
  /* Reduce padding */
}

/* Sort Icon Styles */
.p-sortIcon {
  font-size: 0.75rem;
  /* Smaller font size for sort icon */
  margin-left: 0.25rem;
  /* Margin to separate icon from text */
  color: #999;
  /* Lighter color for sort icon */
}

.p-sortable-column .p-sortable-column-icon {
  position: absolute;
  right: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
}

.flex {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
}

.flex p-inputicon {
  position: relative;
  left: 170px;
  top: 2px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .p-datatable {
    font-size: 0.75rem;
    /* Even smaller font size for mobile */
  }

  .p-datatable-header,
  .p-datatable-footer,
  .p-datatable-thead>tr>th,
  .p-datatable-tfoot>tr>td,
  .p-datatable-tbody>tr>td {
    padding: 0.25rem;
    /* Further reduce padding for mobile */
    flex-shrink: 0;
    /* Prevent shrinking */
  }

  .p-paginator {
    padding: 0.25rem;
    /* Further reduce padding for paginator on mobile */
    font-size: 0.75rem;
    /* Smaller font size for paginator on mobile */
  }

  .p-sortIcon {
    font-size: 0.65rem;
    /* Smaller font size for sort icon on mobile */
  }

  .p-sortable-column .p-sortable-column-icon {
    right: 0.25rem;
  }
}

.table-responsive {
  position: relative;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  /* Optional for a dimming effect */
  z-index: 1;
}

.p-dropdown-items {
  padding-left: 0rem !important;
}

.form-control {
  border: var(--bs-border-width) solid #cccccc !important; //border: $input-border-width solid $input-border-color;
  font-size: 0.75rem !important;
}

.form-select {
  border: var(--bs-border-width) solid #cccccc !important;
  font-size: 0.75rem !important;
}

.ng-select.ng-select-single .ng-select-container {
  min-height: 25px !important;
}


.hr {
  margin: 7px !important;
}

.position {
  display: contents !important;
}

.form-check-input {
  border: var(--bs-border-width) solid #cccccc !important;
}

.dropzone {
  border: 2px dashed #cdcaca !important;
}

.p-datatable-header,
.p-datatable-footer {
  padding: 0.5rem;
  /* Reduce padding for header and footer */
  background-color: #f1f1f1;
  /* Light background for header and footer */
  border-bottom: 1px solid #ddd;
  /* Border bottom for header */
  border-top: 1px solid #ddd;
  /* Border top for footer */
}

.p-datatable-thead>tr>th {
  padding: 0.5rem;
  /* Reduce padding for table head cells */
  background-color: #f7f7f7;
  /* Light background for table head */
  border-bottom: 1px solid #ddd;
  /* Border bottom for table head cells */
  border-right: 1px solid #ddd;
  /* Border right for table head cells */
  text-align: left;
  /* Align text to the left */
  color: #333;
  /* Text color */
  font-weight: bold;
  /* Bold text */
  position: relative;
  /* For positioning sort icons */
}

.p-datatable-tfoot>tr>td {
  padding: 0.5rem;
  /* Reduce padding for table foot cells */
  background-color: #f7f7f7;
  /* Light background for table foot */
  border-top: 1px solid #ddd;
  /* Border top for table foot cells */
  border-right: 1px solid #ddd;
  /* Border right for table foot cells */
  text-align: left;
  /* Align text to the left */
  color: #333;
  /* Text color */
}

.p-datatable-tbody {
  overflow-y: auto;
  /* Enable vertical scrolling */
  flex-grow: 1;
  /* Allow the body to grow and take up remaining space */
}

.p-datatable-tbody>tr>td {
  padding: 0.3rem;
  /* Reduce padding for table body cells */
  border-bottom: 1px solid #ddd;
  /* Border bottom for table body cells */
  border-right: 1px solid #ddd;
  /* Border right for table body cells */
  background-color: #fff;
  /* White background for table body */
}

.p-datatable-tbody>tr:nth-child(even)>td {
  background-color: #f5f5f5;
  /* Alternate row color */
}

.p-datatable-tbody>tr:hover>td {
  background-color: #eaeaea;
  /* Hover color */
}

.p-datatable-tbody>tr>td>.p-column-title {
  font-size: 0.75rem;
  /* Smaller font size for column titles */
  padding: 0.25rem;
  /* Reduce padding for column titles */
  color: #666;
  /* Text color for column titles */
}

.p-paginator {
  padding: 0.5rem;
  /* Reduce padding for paginator */
  font-size: 0.875rem;
  /* Smaller font size for paginator */
  border-top: 1px solid #ddd;
  /* Border top for paginator */
  background-color: #f1f1f1;
  /* Light background for paginator */
}

/* Adjust input, button, and icon sizes within the table */
.p-datatable input,
.p-datatable button,
.p-datatable .p-paginator .p-paginator-element {
  font-size: 0.875rem;
  /* Smaller font size */
  padding: 0.25rem 0.5rem;
  /* Reduce padding */
}

/* Sort Icon Styles */
.p-sortIcon {
  font-size: 0.75rem;
  /* Smaller font size for sort icon */
  margin-left: 0.25rem;
  /* Margin to separate icon from text */
  color: #999;
  /* Lighter color for sort icon */
}

.p-sortable-column .p-sortable-column-icon {
  position: absolute;
  right: 0.1rem;
  top: 50%;
  transform: translateY(-50%);
}

.flex {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
}

.flex p-inputicon {
  position: relative;
  left: 170px;
  top: 2px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .p-datatable {
    font-size: 0.75rem;
    /* Even smaller font size for mobile */
  }

  .p-datatable-header,
  .p-datatable-footer,
  .p-datatable-thead>tr>th,
  .p-datatable-tfoot>tr>td,
  .p-datatable-tbody>tr>td {
    padding: 0.25rem;
    /* Further reduce padding for mobile */
    flex-shrink: 0;
    /* Prevent shrinking */
  }

  .p-paginator {
    padding: 0.25rem;
    /* Further reduce padding for paginator on mobile */
    font-size: 0.75rem;
    /* Smaller font size for paginator on mobile */
  }

  .p-sortIcon {
    font-size: 0.65rem;
    /* Smaller font size for sort icon on mobile */
  }

  .p-sortable-column .p-sortable-column-icon {
    right: 0.25rem;
  }
}

.table-responsive {
  position: relative;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  /* Optional for a dimming effect */
  z-index: 1;
}

.p-dropdown-items {
  padding-left: 0rem !important;
}

.form-control {
  border: var(--bs-border-width) solid #cccccc !important; //border: $input-border-width solid $input-border-color;
}

.form-select {
  border: var(--bs-border-width) solid #cccccc !important;
}

.hr {
  margin: 7px !important;
}

.position {
  display: contents !important;
}

.form-check-input {
  border: var(--bs-border-width) solid #cccccc !important;
}

.dropzone {
  border: 2px dashed #cdcaca !important;
}

.btn {
  --bs-btn-padding-x: 11px !important;
  --bs-btn-padding-y: 6px !important;
  --bs-btn-font-size: 11px !important;
}

.form-container {
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 5px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  position: relative;
}

.form-title {
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: -12px;
  left: 20px;
  background: #fff;
  padding: 0 5px;
}

.p-dialog .p-dialog-header,
.p-dialog .p-dialog-footer {
  padding: 1rem !important;
}

.p-confirm-dialog-reject,
.p-confirm-dialog-accept {
  padding: 5px 10px !important;
}

.p-dialog-footer button {
  margin-left: 0.5rem !important;
}

.p-dialog .p-dialog-content {
  padding: 0 1.5rem 0.5rem !important;
}

.p-confirm-dialog-accept {
  background-color: #5b73e8 !important;
  border-color: #5b73e8 !important;
}

.p-confirm-dialog-icon {
  color: rgb(244 106 106) !important;
}

.p-confirm-dialog-reject {
  background-color: rgb(244 106 106) !important;
  border-color: rgb(244 106 106) !important;
}

//   .form-control {
//     display: block;
//     width: 100%;
//     padding: .25rem .5rem;
//     font-size: .9rem;
//     font-weight: 400;
//     line-height: 25px !important;
//     color: var(--bs-body-color);
//     appearance: none;
//     background-color: var(--bs-input-bg);
//     background-clip: padding-box;
//     border: var(--bs-border-width) solid var(--bs-border-color);
//     border-radius: var(--bs-border-radius);
//     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
// }

// .ng-select .ng-select-container {
//   color: #333;
//   background-color: #fff;
//   border-radius: 4px;
//   border: 1px solid #ccc;
//   min-height: 28px !important;
//   align-items: center;
// }
.ng-select.ng-select-single .ng-select-container {
  height: 28px !important;
}

.ddl {
  .p-dropdown {
    width: 255px
  }
}

.ddl1 {
  .p-dropdown {
    width: 130px
  }
}

.p-dropdown-item,
.p-dropdown-label {
  font-size: 14px;
}

.p-datatable-wrapper .p-scroller {
  min-height: 200px;
}

.p-datatable-table th,
.p-datatable-table td {
  white-space: nowrap;
  // overflow: hidden;
  text-overflow: ellipsis;
}