// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: var(--#{$prefix}box-shadow);
}

.card-drop {
  color: var(--#{$prefix}body-color);
}

.card-title {
  font-size: 16px;
  margin: 0 0 7px 0;
  color: var(--#{$prefix}heading-color) !important;

}

.card-title-desc {
  margin-bottom: 8px;//margin-bottom: 24px;  
}

